import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "translations-and-the-ui",
      "style": {
        "position": "relative"
      }
    }}>{`Translations and the UI`}<a parentName="h1" {...{
        "href": "#translations-and-the-ui",
        "aria-label": "translations and the ui permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`Translations can present a challenge. Our philosophy is that
"content creators write the website", so the `}<inlineCode parentName="p">{`content`}</inlineCode>{` folder therefore decides how the site is rendered,
including translated content. However, this is an issue when translating inline UI elements that we won't
provide in MDX (like the Search bar). This means there's a break in how we can translate copy like "Search"
and "No results" into the current locale...`}</p>
    <h2 {...{
      "id": "why-not-i18n-or-another-library",
      "style": {
        "position": "relative"
      }
    }}>{`Why Not i18n or another library?`}<a parentName="h2" {...{
        "href": "#why-not-i18n-or-another-library",
        "aria-label": "why not i18n or another library permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`i18n is a Javascript API that allows developers an easy and "lightweight" way of translating
textual content for a node based application. It's been great, but the reason we decided NOT to use it for this
project is because it would couple translated content with our code. This is a big no-no, as it goes against our WYSIWYG
philosophy for the content folder and means that content creators would have to translate text in 2 different places.`}</p>
    <p>{`Add to this the fact that the i18n package for gatsby, and other frameworks, has a tendency to be larger than necessary.
We believe that keeping things simple will be a positive for the scale of our application. Therefore, we present:`}</p>
    <h2 {...{
      "id": "usetranslation-aka-maximumlang",
      "style": {
        "position": "relative"
      }
    }}>{`useTranslation aka MaximumLang`}<a parentName="h2" {...{
        "href": "#usetranslation-aka-maximumlang",
        "aria-label": "usetranslation aka maximumlang permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`useTranslation is a hook developed by Réjon Taylor-Foster with inspiration from and reference to
`}<a parentName="p" {...{
        "href": "https://w11i.me/how-to-build-multilingual-website-in-next-js"
      }}>{`this blog`}</a>{`. It is capable of being initialized
inside a functional components and exports the current site's locale, and a `}<inlineCode parentName="p">{`t`}</inlineCode>{` function.`}</p>
    <h3 {...{
      "id": "how-to-translate-in-code",
      "style": {
        "position": "relative"
      }
    }}>{`How to translate in code`}<a parentName="h3" {...{
        "href": "#how-to-translate-in-code",
        "aria-label": "how to translate in code permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`If your `}<inlineCode parentName="p">{`en/UI.json`}</inlineCode>{` looks like this:`}</p>
    <div {...{
      "className": "gatsby-code-title prism-code-title"
    }}>{`en/UI.json`}</div>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "UI": {
    "Language": "English",
    "Search": "Search",
    "No_Results": "No results for '{{searchText}}'",
    "Home": "Home"
  }
}
`}</code></pre>
    <p>{`you can have it translated and render the word "Search" by writing the following:`}</p>
    <div {...{
      "className": "gatsby-code-title prism-code-title"
    }}>{`component-example.js`}</div>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import useTranslation from "@modules/localization/useTranslation";

const ComponentExample = () => {
  const { locale, t } = useTranslation();

  return <p>{t("Search")}</p>;
};
`}</code></pre>
    <h3 {...{
      "id": "langspaces",
      "style": {
        "position": "relative"
      }
    }}>{`LangSpaces`}<a parentName="h3" {...{
        "href": "#langspaces",
        "aria-label": "langspaces permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`We can extend the capabilities of this simple `}<inlineCode parentName="p">{`t`}</inlineCode>{` function with what we call LangSpaces (Language Spaces):`}</p>
    <div {...{
      "className": "gatsby-code-title prism-code-title"
    }}>{`en/UI.json`}</div>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "UI": {
    "Language": "English",
    "Search": "Search",
    "No_Results": "No results for '{{searchText}}'",
    "Home": "Home",
    "Errors": {
      "error_a": "This is an error inside of a langspace called Errors"
    }
  }
}
`}</code></pre>
    <p>{`you can have it translate and render "error_a" by writing the following:`}</p>
    <div {...{
      "className": "gatsby-code-title prism-code-title"
    }}>{`component-langspace-example.js`}</div>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import useTranslation from "@modules/localization/useTranslation";

const ComponentExample = () => {
  const { locale, t } = useTranslation();

  return <p>{t("error_a", "Errors")}</p>;
};
`}</code></pre>
    <p>{`For the sake of quality of life it's possible to pass in a default lang space for your current translation as well:`}</p>
    <div {...{
      "className": "gatsby-code-title prism-code-title"
    }}>{`component-langspace-default-example.js`}</div>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import useTranslation from "@modules/localization/useTranslation";

const ComponentExample = () => {
  const { locale, t } = useTranslation("Errors"); //<- Notice

  return (
    <p>{t("error_a")}</p> //<- Notice
  );
};
`}</code></pre>
    <h3 {...{
      "id": "variables-and-plurals",
      "style": {
        "position": "relative"
      }
    }}>{`Variables and Plurals`}<a parentName="h3" {...{
        "href": "#variables-and-plurals",
        "aria-label": "variables and plurals permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`You want the variable of counter included and with plurals? No problem, anything inside `}<inlineCode parentName="p">{`{{}}`}</inlineCode>{` will be replaced when rendered:`}</p>
    <div {...{
      "className": "gatsby-code-title prism-code-title"
    }}>{`en/UI.json`}</div>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "UI": {
    "Friend": "A Friend",
    "Friend_plural": "{{count}} Friends",
    "VariableExample": "My name is {{name}}"
  }
}
`}</code></pre>
    <div {...{
      "className": "gatsby-code-title prism-code-title"
    }}>{`variable-component-example.js`}</div>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import useTranslation from '@modules/localization/useTranslation'

const ComponentExample = () => {
   const {locale, t} = useTranslation();

  return (
       <p>{t("Friend", null, {count: 0})}</p> //0 Friends
       <p>{t("Friend", null, {count: 1})}</p> //Friend
       <p>{t("Friend", null, {count: 2})}</p> //2 Friends, etc.
       <p>{t("VariableExample", null, {name: "Réjon"})}</p> // My name is Réjon
   )
}
`}</code></pre>
    <h3 {...{
      "id": "advanced-plurals",
      "style": {
        "position": "relative"
      }
    }}>{`Advanced Plurals`}<a parentName="h3" {...{
        "href": "#advanced-plurals",
        "aria-label": "advanced plurals permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`Because different locales can have multiple plurals, and different ways of counting (i.e. Arabic),
we have a system in place to handle counting differently if the strict variable is added to the `}<inlineCode parentName="p">{`t`}</inlineCode>{` function like so:
`}<inlineCode parentName="p">{`t('Friend', null, {count: 1, strict: true})`}</inlineCode>{`.`}</p>
    <p>{`Your json will look like:`}</p>
    <div {...{
      "className": "gatsby-code-title prism-code-title"
    }}>{`en/UI.json`}</div>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "UI": {
    "Friend": "A Friend",
    "Friend_plural": "{{count}} Friends",
    "Friend_0": "No Friends",
    "Friend_1": "A Friend",
    "Friend_2": "Two Friends",
    "Friend_3": "Few Friends",
    "Friend_4": "Many Friends",
    "Friend_5": "Other/Lots of Friends"
  }
}
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`NOTE: `}<inlineCode parentName="p">{`Friend`}</inlineCode>{` and `}<inlineCode parentName="p">{`Friend_plural`}</inlineCode>{` are still valid, but will be overriden when `}<inlineCode parentName="p">{`strict`}</inlineCode>{` is provided to the variables argument.`}</p>
    </blockquote>
    <p>{`Your output will look something like this:`}</p>
    <div {...{
      "className": "gatsby-code-title prism-code-title"
    }}>{`advanced-variable-example.js`}</div>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import useTranslation from '@modules/localization/useTranslation'

const ComponentExample = () => {
   const {locale, t} = useTranslation();

  return (
       <p>{t("Friend", null, {count: 0, strict: true})}</p> //No Friends (key: Friend_0)
       <p>{t("Friend", null, {count: 1, strict: true})}</p> //A Friend (key: Friend_1)
       <p>{t("Friend", null, {count: 2, strict: true})}</p> //Two Friends (key: Friend_2)
       <p>{t("Friend", null, {count: 5, strict: true})}</p> //Other/Lots of Friends (key: Friend_5)
   )
}
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`NOTE: If your count goes past 4 or is less than 0, it will default to key_5`}</p>
    </blockquote>
    <h3 {...{
      "id": "getting-a-localized-string-from-another-locale",
      "style": {
        "position": "relative"
      }
    }}>{`Getting a localized string from another locale`}<a parentName="h3" {...{
        "href": "#getting-a-localized-string-from-another-locale",
        "aria-label": "getting a localized string from another locale permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`Let's say you're currently on the English locale, but you need a string from the French locale,
but also want to keep your current locale. Say no more fam:`}</p>
    <div {...{
      "className": "gatsby-code-title prism-code-title"
    }}>{`en/UI.json`}</div>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "UI": {
    "Language": "English"
  }
}
`}</code></pre>
    <div {...{
      "className": "gatsby-code-title prism-code-title"
    }}>{`fr/UI.json`}</div>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "UI": {
    "Language": "Français"
  }
}
`}</code></pre>
    <div {...{
      "className": "gatsby-code-title prism-code-title"
    }}>{`multilang-example.js`}</div>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import useTranslation from '@modules/localization/useTranslation'

const ComponentExample = () => {
   const {locale, t} = useTranslation(); //Current locale is "en"

  return (
       <p>{t("Language")}</p> //English
       <p>{t("Language",null,null,'fr')}</p> //Français
   )
}
`}</code></pre>
    <p>{`These are just examples for the sake of documentation. For an in-depth on how the function works,
see the `}<a parentName="p" {...{
        "href": "https://github.com/kernel-community/kernel-v2/blob/main/src/modules/localization/useTranslation.js"
      }}>{`comments in code`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      